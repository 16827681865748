<template>
  <div class="article-list">
    <error :errors="downloadError" />
    <b-overlay :show="formBusy" rounded="sm">
      <b-row v-if="downloads.length > 0">
        <b-col v-for="download in downloads" md="3" :key="download.id" sm="6">
          <portlet title=" " fluidHeight head-class="kt-portlet__head--noborder">
            <template v-slot:toolbar>
              <b-button v-b-tooltip title="بېسىپ چۈشۈرىۋېلىڭ" :href="download.download_url" target="_blank" class="text-decoration-none border-0 btn-clean btn-icon btn-icon-md">
                <i class="fa fa-download"></i>
              </b-button>
            </template>
            <template v-slot:body>
              <div class="kt-widget kt-widget--user-profile-2">
                <div class="kt-widget__head" style="margin-top: -65px">
                  <div class="kt-widget__media">
                    <b-img class="kt-widget__img" rounded="" height="50" :src="iconSrc(download.file_type)" inner-svg alt="image" />
                  </div>
                  <div class="kt-widget__info">
                    <a class="kt-widget__username">
                      {{ download.alias }}
                    </a>
                  </div>
                </div>
                <div class="kt-widget__body">
                  <div class="kt-widget__item">
                    <div class="kt-widget__contact" >
                      <span class="kt-widget__label">ھۆججەت سىغىمى: </span>
                      <span class="kt-widget__data" dir="ltr">{{ download.file_size }}</span>
                    </div>
                  </div>
                </div>
                <div class="kt-widget__footer">
                  <b-button type="button" @click="deleteItem(download.id)" variant="danger" class="btn-upper">ئۆچۈرۈش</b-button>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
        <b-col md="3" sm="6">
          <portlet fluidHeight head-class="kt-portlet__head--noborder">
            <template v-slot:body>
              <div class="kt-widget kt-widget--user-profile-2">
                <div class="kt-widget__body d-flex align-items-center justify-content-center">
                  <b-button :to="{name: 'articles.download.create', params: {aid}}" size="xl" block class="text-decoration-none border-0 btn-clean d-flex align-items-center justify-content-center" style="flex-grow: 1; height: 100%;">
                    <i class="fa fa-cloud-upload-alt"></i> يېڭى ھۆججەت يوللاش
                  </b-button>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <portlet v-if="downloads.length < 1" class="kt-callout">
        <template v-slot:body>
          <div class="kt-callout__body">
            <div class="kt-callout__content">
              <h3 class="kt-callout__title">ئۇچۇر تېپىلمىدى</h3>
              <p class="kt-callout__desc">
                مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، يېڭىدىن ئۇچۇر قوشۇڭ
              </p>
            </div>
            <div class="kt-callout__action">
              <b-button :to="{name: 'articles.download.create', params: {aid}}" class="btn-custom btn-bold btn-upper btn-font-sm  btn-success">يېڭىدىن قوشۇش</b-button>
            </div>
          </div>
        </template>
      </portlet>
    </b-overlay>
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import Error from "../../../partials/widgets/Error";
  import Portlet from "../../../partials/content/Portlet";
  import { mapGetters } from "vuex";
  import articleMixin from "../../../../mixins/articleMixin";
  import articleDownloadMixin from "../../../../mixins/articleDownload.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import {SET_ARTICLE} from "../../../../store/types";
  import Session from "@/common/Session";
  export default {
    name: "index",
    mixins: [ articleMixin, formBusyMixin, articleDownloadMixin ],
    components: { Error, Portlet },
    computed: {
      ...mapGetters(["downloadError", "article"]),
      aid(){
        return this.$route.params.aid;
      },
      breadTitle(){
        return `${this.article.title} گە دائىر بارلىق ھۆججەتلەر `;
      },
    },
    created() {
      if ( Session.has(`article_${this.aid}`) ){
        this.$store.commit(SET_ARTICLE, Session.get(`article_${this.aid}`));
      }else {
        this.findArticle(this.aid, false)
      }
      this.getArticleDownloads(this.aid);
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "ئەسەر باشقۇرۇش", route: { name: "articles.list" } },
        { title: this.breadTitle },
      ]);
    },
    data(){
      return {
        formBusy: false,
      };
    },
    methods: {
      deleteItem(id) {
        this.$bvModal.msgBoxConfirm('مەزكۇر ھۆججەتنى ئ‍ۆچۈرسىڭىز ئەسلىگە قايتۇرغىلى بولمايدۇ، ئۆچۈرۈشنى جەزىملەشتۈرەمسىز ؟', {
          title: 'راستىنلا ئۆچۈرەمسىز ؟',
          okVariant: 'danger',
          okTitle: 'جەزىملەشتۈرىمەن',
          cancelTitle: 'ياق',
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if ( ! value ) return;
          this.deleteArticleDownload(this.aid, id)
            .then(response=>{
              this.getArticleDownloads(this.aid);
            })
        })
        .catch(err => {
          // An error occurred
        })
      },
      iconSrc(file_type){
        switch(file_type){
          case "pdf":
            return "/assets/media/files/pdf.svg";
          case "word":
            return "/assets/media/files/doc.svg";
          case "excel":
            return "/assets/media/files/csv.svg";
        }
      }
    }
  }
</script>

<style>
  .kt-widget__head .kt-widget__info {
    overflow: hidden;
  }

  .kt-widget__section {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    height: 74px;
  }

  .kt-widget__data {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .kt-widget__username {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: justify;
    display: block;
    width: 100%;
  }
</style>
