import handleReject from "../common/handleReject";
import {
  DELETE_DOWNLOAD,
  GET_DOWNLOAD, GET_DOWNLOADS,
  SET_DOWNLOAD_ERROR,
  STORE_DOWNLOAD
} from "../store/modules/articleDownload.module";

import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["download", "downloads", "downloadError"])
  },
  methods: {
    deleteArticleDownload(aid, id){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(DELETE_DOWNLOAD, { aid, id })
        .then(response=>{
          this.hideBusy();
          resolve(response);
        })
        .catch(response=>{
          this.hideBusy();
          handleReject(this.$store, response, SET_DOWNLOAD_ERROR);
          reject(response);
        })
      })
    },
    storeArticleDownload(aid, formData){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(STORE_DOWNLOAD, { aid, formData })
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    getArticleDownload(aid, id, busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(GET_DOWNLOAD, { aid, id })
        .then(response=>{
          busy && this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          busy && this.hideBusy();
          reject(response);
        })
      })
    },
    getArticleDownloads(aid,busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(GET_DOWNLOADS, aid)
        .then(response=>{
          busy && this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          busy && this.hideBusy();
          reject(response);
        })
      })
    },
  }
}
